@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@300&family=Mohave:ital,wght@1,600&display=swap");
body {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  overflow-x: visible;
  overflow-y: visible;
  overscroll-behavior: "none";
}

body::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.firebase-emulator-warning {
  display: none;
}
